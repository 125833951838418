<template>
  <div>
    <div class="inputs">
      <b-form-group :label="$t('StartDate')">
        <date-picker
          v-model="from"
          value-type="format"
          :placeholder="$t('SelectD')"
          :format="'YYYY-MM-DD'"
          :clearable="false"
          :lang="lang"
        />
      </b-form-group>
      <b-form-group :label="$t('EndDate')">
        <date-picker
          v-model="to"
          value-type="format"
          :placeholder="$t('SelectD')"
          :format="'YYYY-MM-DD'"
          :clearable="false"
          :lang="lang"
        />
      </b-form-group>
      <PdfDocument
        :method-name="'sellingReportGroupedByOrderNumber'"
        :getter-name="'getSellingReportGrouped'"
        :pdf-name="'GroupedSellingReport'"
        :is-pagination="false"
        :header-name="header"
        :object-value="objectPDF"
        :start-date="from"
        :end-date="to"
        :pdf-title="$t('SellingReport')"
        style="margin-top: 1.3%;"
      />
      <button
        v-b-tooltip.hover="$t('DownloadExcel')"
        style="
          border: 0;
          width: 48px;
          height: 37px;
          background: #ff274f;
          border-radius: 5px;
          margin-top: 1.3%;
        "
        @click="downloadEXCEL"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="white"
          class="bi bi-file-earmark-spreadsheet"
          viewBox="0 0 16 16"
        >
          <path
            d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h3v2H6zm4 0v-2h3v1a1 1 0 0 1-1 1h-2zm3-3h-3v-2h3v2zm-7 0v-2h3v2H6z"
          />
        </svg>
      </button>
    </div>
    <div style="height: 78vh; overflow-y: scroll">
      <table
        ref="exportable_table"
        class="team_table"
      >
        <thead style="position: sticky; top: -2px">
          <tr>
            <th>
              {{ $t("OrderNumber") }}
            </th>
            <th>
              {{ $t("OrderType") }}
            </th>
            <!-- <th>
              {{ $t("ArticleNumber") }}
            </th>
            <th>
              {{ $t("Created") }}
            </th> -->
            <th>
              {{ $t("Price") }}
            </th>
            <th>
              {{ $t("paidAmount") }}
            </th>
            <th>
              {{ $t("RemainingAmount") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(orders, index) in getSellingReportGrouped"
            :key="index"
          >
            <td>{{ orders.orderNumber }}</td>
            <td>{{ $t(orders.orderType) }}</td>
            <!-- <td>{{ orders.articleNumber }}</td>
            <td>{{ orders.created }}</td> -->
            <td>{{ numbersWithcomma(orders.price) }}</td>
            <td>{{ numbersWithcomma(orders.paidAmount) }}</td>
            <td>{{ numbersWithcomma(orders.price - orders.paidAmount) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="getSellingReportGroupedTotalItems > 15">
      <b-pagination
        v-model="pageNumber"
        :total-rows="getSellingReportGroupedTotalItems"
        :per-page="pageSize"
        first-number
        last-number
        align="fill"
        prev-class="prev-itemi"
        next-class="next-itemi"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <span class="previousOrNext"><b-icon-arrow-left />{{ $t("Previous") }}</span>
        </template>
        <template #next-text>
          <span class="previousOrNext">{{ $t("Next") }}<b-icon-arrow-right /></span>
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'jspdf-autotable';
import { mapActions, mapGetters } from 'vuex';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import PdfDocument from '@/components/PdfDocument/DownloadPDF.vue'
import { client } from '../../domainConfig'

export default {
  components: {
    DatePicker,
    PdfDocument,
  },
  data() {
    return {
      from: null,
      to: null,
      pageNumber: 1,
      pageSize: 15,
      client: client.clientName,
      header: [
        {
          orderNumber: this.$t('OrderNumber'),
          orderType: this.$t('OrderType'),
          price: this.$t('Price'),
          paidAmount: this.$t('paidAmount'),
          totalPriceRemaining: this.$t('RemainingAmount'),
        },
      ],
      objectPDF: {
        startDate: null,
        endDate: null,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      },
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
    };
  },
  computed: {
    ...mapGetters(['getSellingReportGrouped',
      'getLoggedInUser',
      'getSellingReportGroupedTotalItems',
    ]),
  },
  watch: {
    from(newValue) {
      if (newValue && this.to) {
        this.sellingReportGroupedByOrderNumber({
          salesType: 'Material',
          startDate: newValue,
          endDate: this.to,
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
        });
      }
    },
    to(newValue) {
      if (newValue && this.from) {
        this.sellingReportGroupedByOrderNumber({
          salesType: 'Material',
          startDate: this.from,
          endDate: newValue,
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
        });
      }
    },
    pageNumber(newValue) {
      this.sellingReportGroupedByOrderNumber({
        salesType: 'Material',
        startDate: this.from,
        endDate: this.to,
        pageNumber: newValue,
        pageSize: this.pageSize,
      });
    },
    pageSize(newValue) {
      this.sellingReportGroupedByOrderNumber({
        salesType: 'Material',
        startDate: this.from,
        endDate: this.to,
        pageNumber: this.pageNumber,
        pageSize: newValue,
      });
    },
  },
  methods: {
    ...mapActions(['sellingReportGroupedByOrderNumber']),
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    generateTableContentCSV(data) {
      const headers = [this.$t('OrderNumber'),
        this.$t('OrderType'),
        this.$t('Price'),
        this.$t('paidAmount'),
        this.$t('RemainingAmount')];
      const body = data.map((item) => {
        return [
          item.orderNumber,
          item.orderType,
          item.price,
          item.paidAmount,
          item.price - item.paidAmount,
        ]
      });
      return { headers, body };
    },
    generateExcel(tableContent) {
      const { headers, body } = tableContent;
      const sheetData = [headers, ...body];

      const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const excelData = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      return excelData;
    },

    downloadEXCEL() {
      this.sellingReportGroupedByOrderNumber({
        salesType: 'Material',
        pageNumber: this.pageNumber,
        pageSize: 50,
        startDate: this.from,
        endDate: this.to,
      }).then(() => {
        const tableContent = this.generateTableContentCSV(this.getSellingReportGrouped);

        const totalPages = this.getSellingReportGrouped;
        const additionalPromises = [];
        for (let pageNumber = 2; pageNumber <= totalPages; pageNumber++) {
          additionalPromises.push(this.downloadPage(pageNumber));
        }

        Promise.all(additionalPromises).then((additionalTableContents) => {
          additionalTableContents.forEach((additionalTableContent) => {
            tableContent.body = tableContent.body.concat(additionalTableContent.body);
          });

          const excelData = this.generateExcel(tableContent);
          FileSaver.saveAs(excelData, 'SellingReportGrouped.xlsx');
        });
      });
    },
  },
}
</script>

<style>
.pt-0 {
  padding-bottom: 0px !important;
}
.inputs {
  display: flex;
  width: 100%;
  gap: 5px;
  margin-bottom: 1%;
}
.team_table td {
  padding: 16px 9px !important;
}
</style>
