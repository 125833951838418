<template>
  <div class="home">
    <SellingReportMaterial />
  </div>
</template>

<script>
import SellingReportMaterial from '@/components/materialsNominvative/SellingReportMaterial.vue'

export default {
  components: {
    SellingReportMaterial,
  },
}
</script>

<style>
.home {
  padding: 80px 15px 15px 120px;
}

@media screen and (max-width: 1200px) {
  .home {
    padding: 80px 15px 15px 20px;
  }
}

</style>
